<template>
  <div class="page">
    <div class="container">
      <div class="title">
        <span class="class-i" >首页</span>
        <span v-for="i in classList" :key="i.id"><span style="font-weight: bold" class="el-icon-arrow-right"></span><span class="class-i">{{i.name}}</span></span>
      </div>
      <div class="v-wrapper">
        <div v-loading="loading" class="video-wrapper">
          <div class="mask" v-if="mask"><span><router-link class="login-btn" to="/login">登录</router-link></span>后可收听完整内容</div>
          <div class="filter-wrapper">
            <div class="video-parent" v-if="videoDom" :style="{backgroundImage: 'url(' + getRealMedialUrl(video?.coverImgUrl) +')'}"></div>
          </div>
          <div class="video-container"  ref="videoDom"></div>
          <div class="video-info-l-wrapper">
            <div class="hendler">
              <span class="mobile">
              <span class="h-item" :class="{active: current === 1}" @click="current = 1">音频简介</span>
              <span class="h-item" :class="{active: current === 2}" @click="current = 2">目录</span>
              <span class="h-item" v-if="!openMode" :class="{active: current === 3}" @click="current = 3">评论</span>
            </span>
              <span class="liked-list">
                <span class="liked-list" @click="like"><i :class="liked ? 'el-icon-star-on liked' : 'el-icon-star-off unlike'"> </i> <span>&nbsp;喜欢</span></span>
              </span>
              <!-- <el-rate @change="start" v-model="rate" :disabled="!!rate || !uname"></el-rate> -->
            </div>
            <div v-show="!isMobile || current === 1" class="">
              <p class="intro-title">音频简介</p>
              <div class="intro-wrapper">
                <div class="img-wrapper">
                  <VideoImage class="image-wrapper" :src="video?.coverImgUrl" />
                </div>
                <div class="content-wrapper">
                  {{video.details}}
                </div>
              </div>
            </div>
            <div class="mobile" v-show="current === 2">
              <div class="scroll-view">
                <div class="ablum-item" :class="{'active': currentPart === index}" v-for="(a, index) in parts" :key="a.id">
                  <span :title="a.name" class="name">
                    <span>{{a.name || (index > 8 ? (index + 1) : ('0' + (index + 1)))}}</span>
                    <span class="active"></span>
                  </span>
                  <span class="try">
                    <span @click="changePlay(a, index)" v-if="currentPart !== index">{{uname ? '播放' : '试看'}}</span>
                    <span v-else class="living">
                      <span v-if="isLiving">播放中</span>
                      <span v-else>已暂停</span>
                    </span>
                  </span>
                </div>
              </div>
            </div>
            <div v-show="!openMode && (!isMobile || current === 3)">
              <p class="intro-title">评论</p>
              <div class="comment-area">
                <!-- <img class="avatar" src="" alt=""> -->
                <span class="avatar"><span class="el-icon-user-solid"></span></span>
                <div class="comment-input">
                  <span v-if="!uname" class="login-tip">请先<router-link class="login-btn" to="/login">登录</router-link></span>
                  <textarea v-model.trim="words" :disabled="!uname" name="" id="" cols="30" placeholder="请输入您的评论..." rows="10"></textarea>
                </div>
                <span class="comment-btn">
                  <el-button size="mini" :disabled="!words" type="primary" @click="comment()">发表评论</el-button>
                </span>
              </div>
              <div>
                <Comment v-for="c in commentList" :key="c.id" :comment="c" :uname="uname" @comment="content => comment(content, c.id)" />
              </div>
            </div>
          </div>
        </div>
        <div class="info-wrapper">
          <div class="issue-wrapper">
            <div>
              <p class="i-title bottom">{{video.name}}</p>
              <p class="sub-title">分集目录</p>
            </div>
            <!-- <p class="i-title">选集 <span class="tip">（共{{video.count || "--"}}集）</span> </p> -->
            <div class="scroll-view">
              <div class="ablum-item" :class="{'active': currentPart === index}" v-for="(a, index) in parts" :key="a.id">
                <span :title="a.name" class="name">
                  <span>{{a.name || (index > 8 ? (index + 1) : ('0' + (index + 1)))}}</span>
                  <span class="active"></span>
                </span>
                <span class="try">
                  <span @click="changePlay(a, index)" v-if="currentPart !== index">试听</span>
                  <span v-else class="living">
                    <span v-if="isLiving">播放中</span>
                    <span v-else>已暂停</span>
                  </span>
                </span>
              </div>
            </div>
          </div>

          <div class="video-info-r-wrapper">
            <p :class="isMobile ? 'intro-title' : 'i-title'">相关推荐</p>
            <el-row :gutter="24">
              <el-col v-for="v in recommend" :key="v.id" :xs="{span: '12'}" :sm="{span: '24'}">
                <div class="v-w" >
                  <NewVideoItem @click="goVideo(v)" :width="300" :video="v" />
                </div>
              </el-col>
            </el-row>
          </div>
        </div>
      </div>

    </div>

    <slot name="footer" />
  </div>
</template>

<script>
import useMixins from './mixins/media.mix'
import NewVideoItem from "@c/NewVideoItem.vue";
import VideoImage from "@c/VideoImage.vue";
import Comment from "@c/Comment.vue";
import { onBeforeUnmount, ref, toRefs, watchEffect } from 'vue'
import { useRouter } from 'vue-router'
import { getRealMedialUrl } from '@/util'

export default {
  name: "Audio",
  props: {
    menuIdMap: Object,
  },
  components: {
    NewVideoItem,
    Comment,
    VideoImage
  },
  setup (props) {
    const player = ref(null)
    const state = ref(null)
    const videoDom = ref(null)
    const router = useRouter()
    const playTimer = ref(null)
    const openMode = ref(process.env.VUE_APP_RUN_MODE === 'open');
    const initVideo = (src, cb) => {
      player.value && player.value.dispose()
      videoDom.value.innerHTML = `
        <audio id="my-player" class="video-js ctrl-audio" controls>
          <source id="my-spanish-audio-track" src="${src}" type="audio/mp3">
        </audio>`
      setTimeout(() => {
        player.value = window.videojs('my-player',
          {
            controlBar: {
              children: [
                { name: 'playToggle' }, // 播放/暂停按钮
                { name: 'currentTimeDisplay' }, // 视频当前已播放时间
                { name: 'timeDivider' },
                { name: 'durationDisplay' }, // 视频播放总时间
                { name: 'progressControl' }, // 播放进度条
                {
                  name: 'volumePanel', // 音量控制
                  inline: false, // 不使用水平方式
                }
              ]
            }
          },
          () => {
            const div = document.createElement('div')
            div.classList.add('media-info')
            const innerHTML = `
          <img class="cover" src="${getRealMedialUrl(state.value.video?.coverImgUrl)}" />
          <div class="other-info"><p class="title-t">${state.value.video.name}</p><p class="current-t">${state.value.parts[state.value.currentPart].name}</p></div>
          `
            div.innerHTML = innerHTML
            videoDom.value.firstElementChild.appendChild(div)
          })
        var track = new window.videojs.AudioTrack({
          id: 'my-spanish-audio-track'
        });
        player.value.audioTracks().addTrack(track);
        player.value.on('play', () => {
          state.value.isLiving = true
          state.value.mask = false
        })
        player.value.on('pause', () => {
          state.value.isLiving = false
        })
        cb && cb()
      })
    }

    const {
      state: _state,
      goVideos,
      changePlay,
      like,
      start,
      comment,
      goVideo,
      startListener,
      endListener
    } = useMixins(initVideo, player, props, videoDom, `<audio id="my-player" class="video-js ctrl-audio" controls>
          <source id="my-spanish-audio-track" src="" type="audio/mp3">
        </audio>`)

    state.value = _state

    watchEffect(() => {
      player.value?.on('play', () => {
        clearTimeout(playTimer.value)
        playTimer.value = setTimeout(() => {
          startListener(_state.id)
        }, 1000)
      })
      player.value?.on('pause', () => {
        clearTimeout(playTimer.value)
        playTimer.value = setTimeout(() => {
          endListener(_state.id)
        }, 1000)
      })
    })

    onBeforeUnmount(() => {
      try {
        player.value?.dispose()
        // eslint-disable-next-line no-empty
      } catch (error) {

      }
    })

    return {
      ...toRefs(_state),
      goVideos,
      changePlay,
      like,
      start,
      comment,
      goVideo,
      router,
      videoDom,
      getRealMedialUrl,
      isMobile: window.innerWidth < 768,
      openMode
    }
  }
};
</script>

<style scoped lang="stylus">

.title {
  color: #aaa;
  font-size: 15px;
  margin-top: 20px;
}

.v-wrapper {
  display: flex;
  margin-top: 20px;
  justify-content: space-between;
  position: relative;

  & + .v-wrapper {
    margin-bottom: 20px;
  }
}

.video-wrapper {
  width: 800px;
  position: relative;
}

.video-info-l-wrapper {
  width: 800px;
  margin-top: 20px;
}

.info-wrapper {
  display flex;
  flex-direction column;
  width: calc(100% - 830px);
  box-sizing: border-box;
}

.issue-wrapper {
  padding: 0 10px 10px 10px;
  // background: #eee;
}

.video-info-r-wrapper {
  margin: 20px 0;
}

::v-deep(.video-js) {
  background-color: rgba(0,0,0,.7);
  // backdrop-filter: blur(8px);
}

.i-title {
  // height: 30px;
  line-height: 30px;
  font-size: 16px;
  color: #444;

  & .tip {
    font-size: 14px;
  }
}

.ablum-item {
  height: 35px;
  line-height: 35px;
  display: flex;
  justify-content: space-between;
  padding: 0 10px;

  &.active {
    background: #fb888a;
    color: #fff;
  }
}

.name {
  width: calc(100% - 40px);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.try, .login {
  color: #ef7c7d;
  font-size: 12px;
  text-decoration: underline;
  cursor: pointer;
  width: 40px;
  text-align: right;
}

.login {
  color: #aaa;
}

.scroll-view {
  overflow: auto;
  height: 500px;
}

.hendler {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #666;
  margin-bottom 10px

  span {
    cursor: pointer;
  }
}

.el-rate {
  border: 1px solid #eee;
  border-radius: 5px;
  padding: 3px;
}

.el-rate::after {
  content: '评分';
  vertical-align: middle;
}

.image-wrapper {
  width: 100%;
  height: 100%;
}

.image-slot {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background: #f5f7fa;
  color: #909399;
  font-size: 30px;
}

.intro-wrapper {
  display: flex;
  justify-content: space-between;

  .img-wrapper {
    padding-left: 50px;
    width: 200px;
    // height: 80px;
    box-sizing: border-box;
  }

  .content-wrapper {
    width: calc(100% - 220px);
  }
}

.intro-title {
  border-bottom: 1px solid #eee;
  margin-bottom: 10px;
  padding-bottom: 5px;
  margin-top: 20px;
  font-size: 15px;
  color: #333;
}

.living {
  color: #fff;
}

.liked {
  color: #f20405;
}

.liked, .unlike {
  font-size: 16px;
}

.liked-list {
  display: flex;
  align-items: center;
}


.v-w {
  margin-bottom: 20px;
  // box-shadow: 3px 2px 7px 0px rgba(0, 0, 0, 0.1);
}

.comment-area {
  display: flex;
  padding: 20px;
  flex-wrap: wrap;
}

.avatar {
  width: 50px;
  height: 50px;
  line-height 50px;
  font-size 28px;
  text-align center;
  color: #888;
  border-radius: 50%;
  background-color: #eee;
  margin-right: 20px;
}

.comment-input {
  flex: 1;
  position: relative;

  .login-tip {
    position: absolute;
    width: 100%;
    text-align: center;
    top: 45%;
  }
}

textarea {
  width: 100%;
  border: none;
  border-radius: 5px;
  background-color: #eee;
  padding: 5px;
  // box-shadow: 1px 1px 5px 0px rgba(0, 0, 0, 0.2);
  box-sizing: border-box;

  &:focus {
    outline: none;
  }
}

.comment-btn {
  margin-left: 20px;
}


.m-video-info-r-wrapper {
  display: none;
}
.video-parent {
  position: absolute;
  background-position: center;
  background-size: cover;
  filter: blur(5px);
  height: 100%;
  width: 100%;
}
.filter-wrapper {
  position: absolute;
  overflow: hidden;
  height: 190px;
  width: 100%;
}

.video-container {
  
  
}
// .video-container::after {
//   position: absolute;
//   width: 100%;
//   height: 100%;
//   left: 0;
//   top: 0;
//   content: ''
// }
.title {
  color: #272727;
}
@media screen and (max-width: 770px) {
  .video-wrapper {
    width: 70%;
    height: 320px;
  }
  .video-info-l-wrapper {
    width: auto;
  }
  .info-wrapper {
    width: calc(30% - 10px);
    // height: 320px;
  }
  .scroll-view {
    height: 320px;
  }
  .name {
    width: calc(100% - 50px);
  }

  .try, .login {
    width: 50px;
  }
  @media screen and (max-width: 767px) {
  .video-wrapper + .info-wrapper {
    width: 100%;
  }
  .video-wrapper {
    width: 100%
    height auto
  }
  .video-info-l-wrapper,.video-info-r-wrapper {
    width: 100%;
    padding-left 0
  }
  .v-wrapper + .v-wrapper {
    display: block
  }
  .h-item {
    border: 2px solid #383d42;
    height: 24px;
    display: inline-block;
    line-height: 24px;
    padding: 0px 10px;
    border-radius: 13px;
    box-sizing: border-box;
    font-size 14px;
    color: #383d42;
    margin-right 18px;
    box-sizing: content-box;
    font-family: '微软雅黑';
    letter-spacing: 1px;
    &.active {
      color: #9e2020;
      border-color #9e2020
    }
  }
  .liked-list {
    color: #383d42;
    font-family: '微软雅黑';
  }
  .intro-wrapper {
    padding-left: 0;
  }
    .v-wrapper {
    display: block
  }
  .issue-wrapper {
    display: none;
  }
  .intro-wrapper .img-wrapper {
    padding-left: 0;
  }
  .filter-wrapper {
    height: 120px
  }
  .scroll-view {
    max-height: 320px;
  }
  .v-title {
    font-size 19px
  }
  .intro-title {
    font-size 17px
  }
  .content-wrapper {
    font-size 15px
  }
  .v-wrapper {
    font-size 15px
  }
}
}
</style>
<style lang="stylus">
.ctrl-audio {
  width: 100%;
  height: 190px;

  .vjs-big-play-button {
    display: none;
  }

  .vjs-control-bar {
    display: flex;
  }

  .media-info {
    display: flex;
    height: 155px;
    padding-left 30px
    justify-content: space-between;
    align-items center

    .cover {
      height: 100px;
      max-width 200px
      object-fit: contain;
    }

    .other-info {
      width: calc(100% - 240px);
      box-sizing: border-box;
      padding: 5px;
      padding-left 10px

      .title-t {
        font-size: 30px;
        height: 35px;
        line-height: 35px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        width: 100%;
      }

      .current-t {
        font-size 18px;
        margin-top 20px
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        width: 100%;
        color: #fff;
      }
    }
  }
}
.bottom {
  border-bottom 1px solid rgb(188, 161, 132);
  position relative;
  margin-bottom 20px
  font-weight bold
}
.bottom::before {
  content '';
  width 40%;
  border-bottom 3px solid rgb(174,97,102);
  display inline-block;
  position absolute;
  bottom -1px;
}
.sub-title {
  margin-bottom 15px
}
.mask {
  position: absolute;
  height: 190px;
  width: 800px;
  top: 0;
  background: rgba(0,0,0,.5);
  left: 0;
  z-index: 1000000;
  line-height 190px
  text-align center;
  font-size 20px;
  color: #fff
}
.mask .login-btn {
  font-size 22px
  // height: 40px
  // line-height 40px
  padding: 10px
  border-radius 10px;
  margin-right 10px
}
@media screen and (max-width: 767px) {
  .mask {
    height: 120px;
    line-height 120px;
    width: 100%;
  }
  .ctrl-audio {
    height: 120px;
  }
  .ctrl-audio .media-info {
    height 85px
  }
  .ctrl-audio .media-info .cover {
    height: 55px;
    max-width 120px;
  }
  .ctrl-audio .media-info .other-info {
    width: calc(100% - 120px);
  }
  .ctrl-audio .media-info .other-info .title-t {
    font-size 19px
  }
  .ctrl-audio .media-info .other-info .current-t {
    font-size 16px;
    margin-top: 5px;
  }
}
</style>
